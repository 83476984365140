<script setup lang="ts">
const { logout } = useAuthStore()

const auth = ref({
  name: 'Mekan Flores',
  email: 'mekan.flores@go.team',
})
const links = [
  {
    label: 'Dashboard',
    to: '/',
    leading: 'i-ph-chart-bar',
  },
  {
    label: 'Playbacks',
    to: '/playbacks',
    leading: 'i-ph-arrow-counter-clockwise',
  },
  {
    label: 'Job Posts',
    to: '/job-posts',
    leading: 'i-ph-briefcase',
  },
]
</script>

<template>
  <div
    container="padded"
    grid="~ cols-[1fr_1fr]"
    class="z-10 gap-4 bg-base py-2.5"
    border="b base"
  >
    <div class="flex flex-row gap-4">
      <AppLogo />
      <GNavLink
        v-for="(link, i) in links"
        :key="i" v-bind="link"
        nav-link="text-gray"
        nav-link-active="text-primary"
        nav-link-inactive="text-gray"
      />
    </div>
    <div
      class="w-full flex items-center justify-between gap-x-2 md:justify-end"
    >
      <div class="flex items-center space-x-3">
        <GThemeSwitcher />
        <SettingsColorMode />
        <GButton
          btn="text-gray"

          label="i-ph-question"
          icon square
        />
        <GButton
          btn="text-gray"
          square
          label="i-ph-gear"
          icon
          to="/settings/organisation"
        />
        <GIndicator indicator="solid-error" size="xs" label="99+">
          <GButton
            label="i-heroicons-bell group-active:i-heroicons-bell-solid"
            icon
            btn="text-gray"
            square
            class="group"
          />
        </GIndicator>
      </div>

      <div
        border="~ base 0.5"
        class="mx-2 hidden h-full md:block"
      />

      <div class="inline-flex items-center space-x-3">
        <!-- <UserProfileMenu /> -->

        <!-- <GButton
          to="/login"
          label="Logout"
          btn="text-gray"
          trailing="i-heroicons-arrow-long-right-20-solid"
          @click="logout()"
        /> -->
        <div class="flex flex-col text-right">
          <p class="text-xs">
            {{ auth.name }}
          </p>
          <p class="text-xs text-primary">
            {{ auth.email }}
          </p>
        </div>
        <GAvatar
          avatar="soft-primary"
          :label="getInitials(auth.name)"
          size="sm"
          cursor="pointer" @click="logout"
        />
      </div>
    </div>
  </div>
</template>
